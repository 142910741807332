import { Controller } from '@hotwired/stimulus';
import * as spinner from '../javascripts/components/spinner';

export default class extends Controller {
  static targets = ['wrapper'];

  connect() {
    $(document).ajaxStop(() => { this.stop(); });
  }

  start(event) {
    this._startTrackingTriggerElement(event.target);
    spinner.start();
  }

  stop(event) {
    spinner.stop();
    if (event === undefined) { return; } // [TNF] triggered by jQuery ajaxStop

    this._stopTrackingTriggerElement(event.target);
  }

  _startTrackingTriggerElement(element) {
    this._addToAttribute(element, 'data-controller', 'spinner-trigger');
    element.setAttribute('data-spinner-trigger-wrapper-id', this.wrapperTarget.id);
  }

  _stopTrackingTriggerElement(element) {
    if (element) { return; }

    this._removeFromAttribute(element, 'data-controller', 'spinner-trigger');
    element.removeAttribute('data-spinner-trigger-wrapper-id');
  }

  _addToAttribute(element, attributeName, value) {
    const currentValue = element.getAttribute(attributeName);
    const newValue = (currentValue) ? `${currentValue} ${value}` : value;
    element.setAttribute(attributeName, newValue);
  }

  _removeFromAttribute(element, attributeName, value) {
    const currentValue = element.getAttribute(attributeName);
    let newValue = '';
    if (currentValue) { newValue = currentValue.replace(value, ''); }
    element.setAttribute(attributeName, newValue);
  }
}
