import { Controller } from '@hotwired/stimulus';
import 'rangeslider.js';
import triggerEvent from '../../javascripts/utilities/trigger_event';

export default class extends Controller {
  static targets = ['input', 'output'];

  static values = { updateOutput: Boolean };

  connect() {
    this._initRangeSlider();
    this.updateOutput();
  }

  disconnect() {
    $(this.inputTarget).rangeslider('destroy');
  }

  update(value) {
    if (value === this.inputTarget.value) { return; }

    this.inputTarget.value = value;
    this.outputText = value;
    $(this.inputTarget).rangeslider('update', true);
  }

  updateOutput() {
    this.outputText = this.inputTarget.value;
  }

  set outputText(value) {
    if (!this.updateOutputValue) { return; }

    const textValue = value.toString();
    if (textValue === this.outputTarget.textContent) { return; }

    this.outputTarget.textContent = textValue;
  }

  _initRangeSlider() {
    const controller = this;
    const { inputTarget } = this;

    $(inputTarget).rangeslider({
      polyfill: false,
      onSlideEnd(_position, value) {
        if (value === inputTarget.value) { return; }

        inputTarget.value = value;
        controller.outputText = value;

        triggerEvent(inputTarget, 'change');
      }
    });
  }
}
