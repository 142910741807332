import { Controller } from '@hotwired/stimulus';
import MediaQueries from '../../javascripts/utilities/media_queries';

export default class extends Controller {
  static targets = ['links'];

  static classes = ['collapse'];

  connect() {
    if (MediaQueries.isLessThanLg) this._addCollapseClass();
  }

  collapse() {
    if (MediaQueries.isLessThanLg) {
      this._addCollapseClass();
    } else {
      this._removeCollapseClass();
    }
  }

  _addCollapseClass() {
    this.linksTargets.forEach((link) => link.classList.add(this.collapseClass));
  }

  _removeCollapseClass() {
    this.linksTargets.forEach((link) => link.classList.remove(this.collapseClass));
  }
}
