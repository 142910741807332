import * as specify from './specify';

const check = (checkboxes) => {
  checkboxes.prop('checked', true);
};

const uncheck = (checkboxes) => {
  checkboxes.prop('checked', false);
};

const isChecked = (checkbox) => checkbox.prop('checked');

const clearOtherCheckboxes = (currentCheckbox, currentIterationCheckboxId) => {
  const checkboxes = $(`[data-iteration-checkbox-id="${currentIterationCheckboxId}"]`);

  uncheck(checkboxes);
  specify.reset(checkboxes);
  check(currentCheckbox);
};

const clearExclusiveCheckboxes = (currentIterationCheckboxId) => {
  const exclusiveCheckboxes = $(
    `[data-iteration-checkbox-id="${currentIterationCheckboxId}"][data-exclusive="true"]`
  );

  specify.reset(exclusiveCheckboxes);
  uncheck(exclusiveCheckboxes);
};

const toggleCheckboxes = (currentCheckbox) => {
  const currentIterationCheckboxId = currentCheckbox.attr('data-iteration-checkbox-id');

  if (currentCheckbox.attr('data-exclusive') === 'true') {
    clearOtherCheckboxes(currentCheckbox, currentIterationCheckboxId);
  } else {
    clearExclusiveCheckboxes(currentIterationCheckboxId);
  }
};

const toggleCheckboxesOnClick = (selector) => {
  $(selector).on('click', '.js-checkbox', (event) => {
    const currentCheckbox = $(event.currentTarget);

    if (isChecked(currentCheckbox)) {
      specify.focus(currentCheckbox);
      toggleCheckboxes(currentCheckbox);
    } else {
      specify.reset(currentCheckbox);
    }
  });

  $(selector).on('click', '.js-specify', (event) => {
    const currentCheckbox = specify.findCheckbox($(event.currentTarget));

    check(currentCheckbox);
    toggleCheckboxes(currentCheckbox);
  });
};

export { toggleCheckboxes, toggleCheckboxesOnClick };
