import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['linksToSmooth'];

  initialize() {
    if (this.hasLinksToSmoothTarget) { this._smoothenLinks(this.linksToSmoothTargets); }
  }

  scroll(event) {
    const link = event.currentTarget;
    if (link.getAttribute("data-toggle") === "collapse") { return; }

    event.preventDefault();

    const anchor = link.getAttribute('href');
    const adjustementSpace = $('.navbar').outerHeight() + 20;
    const scrollPosition = $(anchor).offset().top - adjustementSpace;

    $("html, body").animate({ scrollTop: scrollPosition }, 500);
  }

  _smoothenLinks(targets) {
    targets.forEach((element) => {
      const links = element.querySelectorAll('a');

      links.forEach((el) => {
        el.setAttribute('data-action', 'click->smooth-scroll#scroll');
      });
    });
  }
}
