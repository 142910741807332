import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['elementToAnimate'];

  animate(event) {
    const element = document.getElementById(event.target.target);
    if (element) this._setUpEffect(event, element);
  }

  elementToAnimateTargetConnected(element) {
    $(element).slideDown();
  }

  _setUpEffect(event, element) {
    if (this.actionsToAddContent.includes(event.target.action)) {
      const templateContent = event.target.templateElement.content.firstElementChild;
      if (templateContent.dataset.effectsEnable === 'true') {
        this._setUpSlideDown(event, templateContent);
      }
    } else if (event.target.action === 'remove' && element.dataset.effectsEnable === 'true') {
      this._slideUp(event, element);
    }
  }

  _setUpSlideDown(event, element) {
    event.preventDefault();
    element.setAttribute('data-utilities--effects-target', 'elementToAnimate');
    $(element).hide();
    event.target.performAction();
  }

  _slideUp(event, element) {
    event.preventDefault();
    $(element).slideUp(400, () => { event.target.performAction(); });
  }

  get actionsToAddContent() {
    return ['append', 'prepend', 'before', 'after'];
  }
}
