import Rails from '@rails/ujs';

const submitForRailsAndTurbo = (form) => {
  if (form.getAttribute('data-remote') === 'true') {
    Rails.fire(form, 'submit');
  } else if (form.requestSubmit) {
    form.requestSubmit();
  } else {
    form.submit();
  }
};

export default submitForRailsAndTurbo;
