// TODO: Fix this the next time the file is edited.
/* eslint-disable */

import { Controller } from '@hotwired/stimulus';
import Ellipsis from 'ellipsis.js';

export default class extends Controller {
  static values = { linesCount: Number };

  connect() {
    this._shortenText();
  }

  _shortenText() {
    var linesCount = this.linesCountValue;

    var ellipsis = Ellipsis({ lines: linesCount });
    ellipsis.add(this.data.element);
  }
}
