import { Controller } from '@hotwired/stimulus';
import { initTimeout } from '../../javascripts/components/flashes';

export default class extends Controller {
  static targets = ['flash'];

  connect() {
    initTimeout(this.flashTarget);
  }
}
