const alphabetScroll = (glossaryController) => {
  const alphabet = $(glossaryController.alphabetTarget);
  const width = alphabet.parent().width();
  const padding = 20;
  const offset = parseInt($('body').css('padding-top'), 10) + padding;
  const scrollTop = $(window).scrollTop();
  const paddingTop = parseInt($('.content-block').css('padding-top'), 10);

  const startOffset = $('.hero').outerHeight() + paddingTop - padding;
  const stopOffset = $(glossaryController.contentTarget).offset().top
    + $(glossaryController.contentTarget).outerHeight()
    + $('.navbar').outerHeight()
    + padding
    - alphabet.outerHeight()
    - startOffset;

  if (scrollTop < startOffset) {
    alphabet.removeClass('fixed').css({
      position: 'relative',
      top: '0'
    });
  } else if (scrollTop < stopOffset) {
    alphabet.addClass('fixed').css({
      position: 'fixed',
      top: offset,
      bottom: '',
      width
    });
  } else {
    alphabet.removeClass('fixed').css({
      position: 'absolute',
      top: '',
      bottom: '0'
    });
  }
};

export default alphabetScroll;
