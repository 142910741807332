// source : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN#polyfill

const numberIsNaNPolyfill = () => {
  /* eslint-disable-next-line func-names */
  Number.isNaN = Number.isNaN || function (value) {
    /* eslint-disable-next-line no-restricted-globals */
    return typeof value === "number" && isNaN(value);
  };
};

export default numberIsNaNPolyfill;
