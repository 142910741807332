import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];

  static values = { showOnInitialize: Boolean, targetId: String };

  initialize() {
    if (this.hasShowOnInitializeValue && this.showOnInitializeValue) {
      this.modalElement.modal();
    }
  }

  triggerTargetDisconnected() {
    const { modalElement } = this;

    modalElement.on('hidden.bs.modal', () => {
      modalElement.modal('dispose');
      modalElement.remove();
    });
    modalElement.modal('hide');
  }

  showWithUpdatedContent(event) {
    const modal = document.getElementById(this.targetIdValue);
    const { modalBodyContent } = event.currentTarget.dataset;

    if (modalBodyContent !== undefined) {
      modal.querySelector('.modal-body').innerHTML = modalBodyContent;
    }
    $(modal).modal();
  }

  get modalElement() {
    return $(`#${this.element.id}`);
  }
}
