import tearDownModals from './bootstrap';
import * as flashes from '../components/flashes';
import scrollTop from '../utilities/scroll_top';
import startRailsUjs from './rails_ujs';
import { addNonceToHeaders, transformNoncesIntoAttributes } from './content_security_policy';

window.PollAndRoll ||= {};

// initFlashesTimeout to remove when turbo stream has replaced all js views [CLC]
const PollAndRoll = {
  initFlashesTimeout: () => true,
  removeFlashes: () => flashes.remove(),
  scrollTop: (selector, comeUpValue) => scrollTop(selector, comeUpValue),
};

window.PollAndRoll = PollAndRoll;

startRailsUjs();

document.addEventListener('turbo:before-cache', () => {
  tearDownModals();
  transformNoncesIntoAttributes();
});

document.addEventListener('turbo:before-fetch-request', (event) => {
  addNonceToHeaders(event);
});
