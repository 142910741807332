import 'vanilla-cookieconsent';

const guiOptions = {
  consent_modal: {
    layout: 'box',
    position: 'bottom left',
    transition: 'zoom',
    swap_buttons: true
  }
};

const languages = {
  en: {
    consent_modal: {
      title: 'We use cookies',
      description: 'Our site uses cookies to keep our site running smoothly and to optimize technical performance, to personalize the way our pages are displayed, or to serve and measure relevant advertisements.',
      primary_btn: {
        text: 'Accept', // 'Accept all'
        role: 'accept_all' // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: 'More options', // 'Reject all'
        role: 'settings' // 'settings' or 'accept_necessary'
      }
    },
    settings_modal: {
      title: 'Cookie preferences',
      save_settings_btn: 'Save settings',
      accept_all_btn: 'Accept all',
      reject_all_btn: 'Reject all',
      close_btn_label: 'Close',
      blocks: [
        {
          title: 'Cookie usage 📢',
          description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/legal" class="cc-link">privacy policy</a>.'
        }, {
          title: 'Essentials',
          description: 'These cookies are necessary for the proper functioning of our website. Without them, this website would not function properly.',
          toggle: {
            value: 'necessary',
            enabled: true,
            // cookie categories with readonly=true are all treated as "necessary cookies"
            readonly: true
          }
        }, {
          title: 'Statistics and advertising',
          description: 'These cookies allow us to collect audience data to better understand our users\' usage, display relevant advertising and measure its effectiveness, on other websites and social networks.',
          toggle: {
            value: 'analytics', // your cookie category
            enabled: true,
            readonly: false
          }
        }, {
          title: 'More information',
          description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
        }
      ]
    }
  },
  fr: {
    consent_modal: {
      title: 'Nous utilisons des cookies',
      description: 'Notre site utilise des cookies pour garantir son bon fonctionnement et optimiser ses performances techniques, personnaliser l\'affichage de nos pages ou diffuser et mesurer des publicités pertinentes.',
      primary_btn: {
        text: 'Accepter',
        role: 'accept_all'
      },
      secondary_btn: {
        text: "Plus d'options",
        role: 'settings'
      }
    },
    settings_modal: {
      title: 'Préférences en matière de cookies',
      save_settings_btn: 'Sauvegarder les paramètres',
      accept_all_btn: 'Accepter tout',
      reject_all_btn: 'Rejeter tout',
      close_btn_label: 'Fermer',
      blocks: [
        {
          title: 'Utilisation des cookies 📢',
          description: 'Nous utilisons des cookies pour assurer les fonctionnalités de base du site web et pour améliorer votre expérience en ligne. Pour chaque catégorie, vous pouvez choisir d\'accepter ou de refuser les cookies quand vous le souhaitez. Pour plus de détails concernant les cookies et les données personnelles, veuillez lire notre <a href="/legal" class="cc-link">politique de confidentialité</a>.'
        }, {
          title: 'Essentiels',
          description: 'Ces cookies sont nécessaires au bon fonctionnement de notre site web. Sans eux, ce site web ne fonctionnerait pas correctement.',
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true
          }
        }, {
          title: 'Statistiques et publicités',
          description: 'Ces cookies nous permettent de collecter des données d\'audience pour de mieux comprendre les usages de nos utilisateurs, d\'afficher de la publicité pertinente et de mesurer son efficacité, sur d\'autres sites internet ainsi que sur les réseaux sociaux.',
          toggle: {
            value: 'analytics',
            enabled: true,
            readonly: false
          }
        }, {
          title: 'Plus d\'information',
          description: 'Pour toute question relative à notre politique en matière de cookies, veuillez <a class="cc-link" href="/contact">nous contacter</a>.',
        }
      ]
    }
  }
};

const init = () => window.initCookieConsent();

const run = (cookieConsent) => {
  cookieConsent.run({
    auto_language: 'document',
    page_scripts: true,
    gui_options: guiOptions,
    languages
  });
};

export { init, run };
