import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { keyCode: Number };

  preventDefault(event) {
    if (this.hasKeyCodeValue && event.keyCode === this.keyCodeValue) {
      event.preventDefault();
    }
  }
}
