import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  triggerEvent(event) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(this._gaEventData(event));
  }

  _gaEventData(event) {
    return JSON.parse(event.currentTarget.getAttribute('data-ga-event-data'));
  }
}
