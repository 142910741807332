import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element']

  static values = { activeElementId: String, disabled: Boolean }

  static classes = ['active']

  initialize() {
    this.disabledValue = true;
  }

  connect() {
    this.disabledValue = false;
    if (this.hasActiveElementIdValue) {
      const activeElement = document.getElementById(this.activeElementIdValue);
      if (activeElement) { this._activateElement(activeElement); }
    }
  }

  disconnect() {
    this.disabledValue = true;
  }

  activeElementIdValueChanged(value, previousValue) {
    if (this.disabledValue === false) {
      const oldActiveElement = document.getElementById(previousValue);
      if (oldActiveElement) { this._disactivateElement(oldActiveElement); }
      const activeElement = document.getElementById(value);
      if (activeElement) { this._activateElement(activeElement); }
    }
  }

  elementTargetConnected(element) {
    if (this.disabledValue === false) { this.activeElementIdValue = element.id; }
  }

  toggle(event) {
    this.activeElementIdValue = event.currentTarget.id;
  }

  _activateElement(element) {
    element.classList.add(this.activeClass);
  }

  _disactivateElement(element) {
    element.classList.remove(this.activeClass);
  }
}
