const cssClass = '.js-flash';

const initTimeout = (selector) => {
  setTimeout(() => { $(selector).alert('close'); }, 12000);
};

const remove = () => {
  $(cssClass).remove();
};

export { initTimeout, remove };
