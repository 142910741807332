import { Controller } from '@hotwired/stimulus';

export default class Select2Controller extends Controller {
  static targets = ['select'];

  connect() {
    this._init();
    this._customSelect();
    this._triggerChangeEvents();
  }

  disconnect() {
    $(this.selectTarget).select2().select2('destroy');
    $(this.selectTarget).off('select2:select');
    if (this.triggerChangeOnUnselect) { $(this.selectTarget).off('select2:unselect'); }
  }

  _init() {
    $(this.selectTarget).select2({ minimumResultsForSearch: Infinity });
  }

  _customSelect() {
    const arrow = this.element.querySelector('.select2-selection__arrow');
    if (arrow) {
      arrow.innerHTML = '<i class="material-icons">keyboard_arrow_down</i>';
    }
  }

  _triggerChangeEvents() {
    this._triggerChangeEventOn('select2:select');
    if (this.triggerChangeOnUnselect) { this._triggerChangeEventOn('select2:unselect'); }
  }

  _triggerChangeEventOn(select2Event) {
    $(this.selectTarget).on(select2Event, (event) => {
      const changeEvent = new Event('change', { bubbles: true, params: event.params });
      event.currentTarget.dispatchEvent(changeEvent);
    });
  }

  get triggerChangeOnUnselect() {
    return this.selectTarget.getAttribute('data-trigger-change-on-unselect');
  }
}
