const findSpinner = () => document.getElementById('spinner');

const updateTimeoutId = (spinner, newTimeoutId) => {
  const currentTimeoutId = spinner.getAttribute('data-timeout-id');
  if (currentTimeoutId) { window.clearTimeout(currentTimeoutId); }
  spinner.setAttribute('data-timeout-id', newTimeoutId);
};

const start = () => {
  const spinner = findSpinner();
  const timeoutId = window.setTimeout(() => {
    spinner.style.display = '';
  }, 1000);
  updateTimeoutId(spinner, timeoutId);
};

const stop = () => {
  const spinner = findSpinner();
  const timeoutId = spinner.getAttribute('data-timeout-id');
  if (timeoutId) {
    window.clearTimeout(timeoutId);
    spinner.removeAttribute('data-timeout-id');
  }
  spinner.style.display = 'none';
};

export { start, stop };
