import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'placeholder',
    'insufficientSample',
    'sufficientSample',
    'result',
    'resultNotSignificant',
    'confidenceLevel'
  ];

  static values = { result: Object };

  static classes = ['hidden', 'notSignificant', 'significant'];

  resultValueChanged() {
    if (!this.hasResultValue) {
      this._toggleVisibility(this.placeholderTarget);
    } else if (this.resultValue.insufficient_sample) {
      this._toggleVisibility(this.insufficientSampleTarget);
    } else {
      this._toggleVisibility(this.sufficientSampleTarget);
      this._toggleSignificanceClass(this.resultValue.significant);
      this._toggleSignificanceLabel(this.resultValue.significant);
      this.confidenceLevelTarget.innerText = this.resultValue.confidence_level;
    }
  }

  _toggleVisibility(target) {
    [
      this.placeholderTarget, this.insufficientSampleTarget, this.sufficientSampleTarget
    ].forEach((t) => {
      if (t === target) {
        t.classList.remove(this.hiddenClass);
      } else {
        t.classList.add(this.hiddenClass);
      }
    });
  }

  _toggleSignificanceClass(significant) {
    if (significant) {
      this.resultTarget.classList.add(this.significantClass);
      this.resultTarget.classList.remove(this.notSignificantClass);
    } else {
      this.resultTarget.classList.add(this.notSignificantClass);
      this.resultTarget.classList.remove(this.significantClass);
    }
  }

  _toggleSignificanceLabel(significant) {
    if (significant) {
      this.resultNotSignificantTarget.classList.add(this.hiddenClass);
    } else {
      this.resultNotSignificantTarget.classList.remove(this.hiddenClass);
    }
  }
}
