const MediaQueries = {
  get isXs() { return MediaQueries.matchMediaQuery('(max-width: 576px)'); },
  get isSm() { return MediaQueries.matchMediaQuery('(min-width: 576px) and (max-width: 768px)'); },
  get isMd() { return MediaQueries.matchMediaQuery('(min-width: 768px) and (max-width: 992px)'); },
  get isLg() { return MediaQueries.matchMediaQuery('(min-width: 992px) and (max-width: 1200px)'); },
  get isXl() { return MediaQueries.matchMediaQuery('(min-width: 1200px)'); },
  get isLessThanLg() { return MediaQueries.matchMediaQuery('(max-width: 992px)'); },
  matchMediaQuery: (query) => window.matchMedia(query).matches
};

export default MediaQueries;
