import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["filteredSelect"]

  updateFilteredSelect(event) {
    const selectedValue = this.filteredSelectTarget.value;

    Array.from(this.filteredSelectTarget.options).forEach((option) => {
      option.remove();
    });

    $(event.currentTarget).select2('data').forEach((selectedData) => {
      this.filteredSelectTarget.append(new Option(
        selectedData.text, selectedData.id, false, selectedData.id === selectedValue
      ));
    });
  }
}
