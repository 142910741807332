import { Controller } from '@hotwired/stimulus';
import alphabetScroll from '../javascripts/pages/guide/alphabet_scroll';
import MediaQueries from '../javascripts/utilities/media_queries';

export default class extends Controller {
  static targets = ['alphabet', 'content'];

  connect() {
    this.scrollAboveXS();
  }

  scrollAlphabet() {
    this.scrollAboveXS();
  }

  scrollAboveXS() {
    if (!MediaQueries.isXs) alphabetScroll(this);
  }
}
