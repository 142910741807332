import { Controller } from '@hotwired/stimulus';
import * as cookieConsent from '../../javascripts/components/cookie_consent';

export default class extends Controller {
  connect() {
    const cc = cookieConsent.init();
    cookieConsent.run(cc);
  }

  disconnect() {
    const cookieBanner = document.getElementById('cc--main');

    if (cookieBanner) {
      cookieBanner.parentNode.removeChild(cookieBanner);
    }
  }
}
