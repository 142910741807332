import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  disconnect() {
    document.body.dispatchEvent(this._ajaxComplete());
  }

  _ajaxComplete() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('ajax:complete', true, false);
    return event;
  }
}
