import { Controller } from '@hotwired/stimulus';
import submitForRailsAndTurbo from '../../javascripts/components/form';

export default class extends Controller {
  static targets = ["formToSubmit"];

  static values = { blurEvent: Boolean };

  submit(event) {
    if (event.type === 'blur' && this.blurEventValue) return;

    const form = this.formToSubmitTarget;

    submitForRailsAndTurbo(form);
  }

  skipBlurOnEnter(event) {
    if (event.keyCode === 13) { this.blurEventValue = true; }
  }
}
