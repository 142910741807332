import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    url: String,
    actionType: { type: String, default: 'replaceInDom' },
    actionTargetId: String,
    attributeToUpdate: String
  };

  async run() {
    const params = {};
    this.inputTargets.forEach((input) => {
      params[input.getAttribute("name")] = input.value;
    });

    const response = await fetch(`${this.urlValue}?${new URLSearchParams(params)}`);
    const responseObject = await response.json();

    if (this.actionTypeValue === 'replaceInDom') {
      this._replaceInDom(responseObject);
    } else if (this.actionTypeValue === 'updateAttribute') {
      this._updateAttribute(responseObject);
    }
  }

  async _replaceInDom(responseObject) {
    Object.entries(responseObject).forEach(([key, value]) => {
      const element = document.getElementById(key);
      if (element) {
        element.innerText = value;
      }
    });
  }

  async _updateAttribute(responseObject) {
    if (this.hasActionTargetIdValue && this.hasAttributeToUpdateValue) {
      const element = document.getElementById(this.actionTargetIdValue);
      if (element) {
        element.setAttribute(this.attributeToUpdateValue, JSON.stringify(responseObject));
      }
    }
  }
}
