import { Controller } from '@hotwired/stimulus';
import Rellax from 'rellax';

export default class extends Controller {
  static targets = ['element'];

  connect() {
    if (this.hasElementTarget) {
      this.elementTargets.forEach((element) => {
        // [TNF] following rellax documentation
        // eslint-disable-next-line no-unused-vars
        const rellax = new Rellax(element);
      });
    }
  }
}
