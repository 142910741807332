import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';

import $ from 'jquery';

import 'bootstrap';
import 'select2/dist/js/select2.full';

import './polyfills';
import './initializers';

Rails.start();

// Global variables imports
global.$ = $;
global.jQuery = $;
global.Rails = Rails;
