import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import * as spinner from '../../javascripts/components/spinner';

export default class extends Controller {
  static values = { url: String, id: String };

  connect() {
    if (this.element.classList.contains('active')) this.addTabContent();
  }

  addTabContent() {
    const url = this.urlValue;
    const id = this.idValue;

    this._triggerJsonRequest(url, id);
  }

  _triggerJsonRequest(url, id) {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      beforeSend: () => {
        spinner.start();
        return true;
      },
      success: (data) => {
        spinner.stop();
        document.querySelector(id).innerHTML = data.html;
      },
      error: (xhr) => {
        spinner.stop();
        console.error(xhr.responseText);
      }
    });
  }
}
