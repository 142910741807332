import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'feature',
    'form',
    'questionsSlider',
    'interviewsSlider'
  ];

  connect() {
    this._setFeatureEventListener();
  }

  update(event) {
    const controller = this;
    const sliderType = event.currentTarget.dataset.pricingSliderType;
    let sliderTargets;
    if (sliderType === 'questions') {
      sliderTargets = this.questionsSliderTargets;
    } else if (sliderType === 'interviews') {
      sliderTargets = this.interviewsSliderTargets;
    }

    sliderTargets.forEach((target) => {
      controller.rangeSliderController(target).update(event.currentTarget.value);
    });
  }

  rangeSliderController(element) {
    return this.application.getControllerForElementAndIdentifier(element, 'components--range-slider');
  }

  _setFeatureEventListener() {
    $(this.featureTargets).on('show.bs.collapse hide.bs.collapse', (event) => {
      event.currentTarget.classList.toggle('pricing-concepts-column__feature--opened');
    });
  }
}
