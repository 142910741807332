import { Controller } from '@hotwired/stimulus';
import MediaQueries from '../../javascripts/utilities/media_queries';

export default class extends Controller {
  static targets = ['navbar', 'collapse', 'dropdownToggle'];

  static classes = ['navbarWithBorder'];

  connect() {
    $(this.collapseTarget).on('show.bs.collapse', () => { this._addBorder(); });
    $(this.collapseTarget).on('hide.bs.collapse', () => { this._removeBorder(); });
  }

  disconnect() {
    $(this.collapseTarget).off('show.bs.collapse');
    $(this.collapseTarget).off('hide.bs.collapse');
  }

  toggleBorder() {
    if (window.scrollY > 10) { this._addBorder(); } else { this._removeBorder(); }
  }

  showDropdown(event) {
    if (MediaQueries.isLessThanLg) { return; }

    this._changeDropdownState(event.currentTarget, 'show');
  }

  hideDropdown(event) {
    if (MediaQueries.isLessThanLg || (
      event.relatedTarget && event.relatedTarget.classList.contains('tooltip')
    )) { return; }

    this._changeDropdownState(event.currentTarget, 'hide');
  }

  _addBorder() {
    this.navbarTarget.classList.add(this.navbarWithBorderClass);
  }

  _removeBorder() {
    this.navbarTarget.classList.remove(this.navbarWithBorderClass);
  }

  _changeDropdownState(element, state) {
    const dropdownToggle = this.dropdownToggleTargets.find(
      (target) => element.contains(target)
    );
    if (dropdownToggle === undefined) { return; }

    $(dropdownToggle).dropdown(state);
  }
}
