import { Controller } from '@hotwired/stimulus';
import initTooltips from '../../javascripts/components/tooltip';

export default class extends Controller {
  static targets = ['element'];

  static values = { disabled: Boolean, placement: String };

  connect() {
    if (this.disabled) return;

    if (this.hasElementTarget) {
      initTooltips(this.elementTargets, this.initOptions);
    }
  }

  disconnect() {
    if (this.disabled) return;

    if (this.hasElementTarget) {
      $(this.elementTargets).tooltip('dispose');

      this.elementTargets.forEach((element) => {
        this._removeRemainingTooltip(element);
      });
    }
  }

  get disabled() {
    return this.disabledValue;
  }

  get initOptions() {
    const options = { trigger: 'hover' };
    if (this.hasPlacementValue) { options.placement = this.placementValue; }
    return options;
  }

  _removeRemainingTooltip(element) {
    const tooltipId = element.getAttribute('aria-describedby');
    if (tooltipId === null) return;

    const tooltip = document.getElementById(tooltipId);
    if (tooltip === null) return;

    tooltip.parentNode.removeChild(tooltip);
  }
}
