// [TNF] source : https://github.com/hotwired/turbo/issues/294#issuecomment-877842232

const addNonceToHeaders = (event) => {
  const { headers } = event.detail.fetchOptions;
  headers['Turbo-Referrer'] = window.location.href;
  headers['X-Turbo-Nonce'] = document.querySelector("meta[name='csp-nonce']").content;
};

const transformNoncesIntoAttributes = () => {
  const scriptTagsWithNonces = document.querySelectorAll('script[nonce]');
  scriptTagsWithNonces.forEach((element) => {
    if (element.nonce) { element.setAttribute('nonce', element.nonce); }
  });
};

export { addNonceToHeaders, transformNoncesIntoAttributes };
