import { Controller } from '@hotwired/stimulus';
import { toggleCheckboxes } from '../javascripts/components/checkbox';

export default class extends Controller {
  static targets = ['checkbox', 'toggleVisibility'];

  static values = { atLeastOneChecked: Boolean };

  static classes = ['visibility'];

  toggleExclusiveCheckboxes(event) {
    toggleCheckboxes($(event.currentTarget));
  }

  updateAtLeastOneCheckedValue() {
    if (this.hasCheckboxTarget) {
      this.atLeastOneCheckedValue = this.checkboxTargets.some((input) => input.checked);
    }
  }

  atLeastOneCheckedValueChanged() {
    if (this.hasToggleVisibilityTarget) {
      if (this.atLeastOneCheckedValue) {
        this.toggleVisibilityTarget.classList.remove(this.visibilityClass);
      } else {
        this.toggleVisibilityTarget.classList.add(this.visibilityClass);
      }
    }
  }
}
