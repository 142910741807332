const specifyClass = '.js-specify';
const checkboxClass = '.js-checkbox';

const reset = (checkboxElements) => {
  checkboxElements.siblings(specifyClass).val('');
};

const findCheckbox = (element) => element.siblings(checkboxClass);

const focus = (currentCheckbox) => {
  if (currentCheckbox.attr('data-specify') === 'true' && currentCheckbox.prop('checked')) {
    currentCheckbox.siblings(specifyClass).focus();
  }
};

export { findCheckbox, focus, reset };
